.testimonal .items {
  padding: 30px;
  transition: 0.5s;
  margin: 10px; /* Added margin for spacing */
}

.testimonal .img {
  position: relative;
}

.testimonal img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.testimonal i {
  position: absolute;
  bottom: 0;
  left: 60px;
  background-color: #FE3030;
  color: #fff;
}

.testimonal .name {
  margin: 25px;
}

.testimonal .name h2 {
  font-size: 20px;
  margin-bottom: 5px;
}

.testimonal .name span {
  color: #FE3030;
}

.testimonal p {
  margin-top: 20px;
  color: grey;
  transition: 0.5s;
}

.testimonal .items:hover {
  background-color: #FE3030;
  color: #fff;
  cursor: pointer;
}

.testimonal .items:hover span,
.testimonal .items:hover p {
  color: #fff;
}

.testimonal .items:hover i {
  background-color: #fff;
  color: #FE3030;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .testimonal .content {
    grid-template-columns: repeat(2, 1fr); /* Two columns for tablets */
  }

  .testimonal .items {
    padding: 25px; /* Adjust padding for tablets */
  }
}

@media (max-width: 768px) {
  .testimonal .content {
    grid-template-columns: 1fr; /* Stack items vertically */
  }

  .testimonal img {
    width: 80px; /* Smaller image on mobile */
    height: 80px;
  }

  .testimonal .name h2 {
    font-size: 18px; /* Slightly smaller font */
  }

  .testimonal .items {
    padding: 20px; /* Adjust padding */
  }
}

@media (max-width: 480px) {
  .testimonal .name h2 {
    font-size: 16px; /* Even smaller font */
  }

  .testimonal .name span {
    font-size: 14px; /* Adjust span size */
  }

  .testimonal p {
    font-size: 14px; /* Smaller paragraph text */
  }

  .testimonal .items {
    padding: 15px; /* Further reduced padding */
  }
}
