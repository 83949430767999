.contacts iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.contacts .right {
  padding: 50px;
}

.contacts h1 {
  font-size: 30px;
  font-weight: 400;
}

.contacts p {
  color: grey;
}

.contacts .items {
  margin: 30px 0;
}

.contacts .box p {
  font-size: 17px;
  margin-top: 10px;
}

.contacts textarea,
.contacts input {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  outline: none;
  border: 1px solid grey;
}

form .flexSB input:nth-child(1) {
  margin-right: 10px;
}

.contacts h3 {
  margin-top: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

.contacts span {
  color: #FE3030;
  font-weight: 600;
  font-size: 15px;
  word-spacing: 5px;
}

/* Media Queries for Responsiveness */

/* Tablet and smaller screens */
@media screen and (max-width: 768px) {
  .contacts .container {
    flex-direction: column;
  }

  .contacts iframe {
    height: 50vh; /* Adjusted iframe height */
  }

  .contacts .right {
    padding: 30px; /* Reduced padding */
  }

  .contacts h1 {
    font-size: 24px; /* Reduced font size */
  }

  .contacts .box p {
    font-size: 15px; /* Adjusted font size */
  }

  .contacts h3 {
    font-size: 18px; /* Adjusted font size */
  }
}

/* Mobile phones */
@media screen and (max-width: 480px) {
  .contacts .right {
    padding: 20px; /* Further reduced padding */
  }

  .contacts h1 {
    font-size: 20px; /* Further reduced font size */
  }

  .contacts .items {
    margin: 20px 0; /* Adjusted margin */
  }

  .contacts .box p {
    font-size: 14px; /* Further reduced font size */
  }

  .contacts span {
    font-size: 14px; /* Reduced font size for span */
  }

  .contacts textarea,
  .contacts input {
    padding: 12px; /* Reduced padding */
  }
}
